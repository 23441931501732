import {
  Button,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "../../../lib/api/axios";

import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

const FilterSheet = ({
  open,
  onOpenChange,
  setStateSelectedFilters,
  stateSelectedFilters,
  filters,
  setFilters,
  fetchProducts,
}) => {
  const { t } = useTranslation();

  const [mainCategories, setMainCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoryBrands, setSubCategoryBrands] = useState([]);

  const getMainCategories = () => {
    mainCategories.length === 0 &&
      axios
        .get("/main-categories")
        .then((res) => {
          setMainCategories(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useEffect(() => {
    getMainCategories();
  }, []);

  // handle change name
  const handleChangeName = (e) => {
    setStateSelectedFilters({
      ...stateSelectedFilters,
      name: e.target.value,
    });
  };

  // handle Select main category
  const handleSelectMainCategory = (e) => {
    const valueName = mainCategories.find((item) => item?.id === e).name;

    setStateSelectedFilters({
      ...stateSelectedFilters,
      mainCategory: {
        name: valueName,
        id: e,
      },
      // reset other filters
      category: {
        name: "",
        id: "",
      },
      subCategory: {
        name: "",
        id: "",
      },
      brand: {
        name: "",
        id: "",
      },
    });

    axios
      .get(`/categories`, {
        params: {
          mainCategoryId: e,
        },
      })
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // handle Select category
  const handleSelectCategory = (e) => {
    const valueName = categories.find((item) => item?.id === e).name;

    setStateSelectedFilters({
      ...stateSelectedFilters,
      category: {
        name: valueName,
        id: e,
      },
      // reset other filters
      subCategory: {
        name: "",
        id: "",
      },
      brand: {
        name: "",
        id: "",
      },
    });

    axios
      .get(`/sub-categories`, {
        params: {
          categoryId: e,
        },
      })
      .then((res) => {
        setSubCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handle Select sub category
  const handleSelectSubCategory = (e) => {
    const valueName = subCategories.find((item) => item?.id === e).name;

    setStateSelectedFilters({
      ...stateSelectedFilters,
      subCategory: {
        name: valueName,
        id: e,
      },
      // reset other filters
      brand: {
        name: "",
        id: "",
      },
    });

    axios
      .get(`/sub-category-brands`, {
        params: {
          subCategoryId: e,
        },
      })
      .then((res) => {
        setSubCategoryBrands(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handle Select sub sub category
  const handleSelectBrand = (e) => {
    const valueName = subCategoryBrands.find((item) => item?.id === e).name;

    setStateSelectedFilters({
      ...stateSelectedFilters,
      brand: {
        name: valueName,
        id: e,
      },
    });
  };

  // options for main category
  const mainCategoryOptions = mainCategories.map((category) => ({
    value: category?.id,
    label: category.name,
  }));

  // options for category
  const categoryOptions = categories.map((category) => ({
    value: category?.id,
    label: category.name,
  }));

  // options for sub category
  const subCategoryOptions = subCategories.map((category) => ({
    value: category?.id,
    label: category.name,
  }));

  const subCategoryBrandOptions = subCategoryBrands.map((brand) => ({
    value: brand?.id,
    label: brand.name,
  }));

  // get categories when main category is selected
  useEffect(() => {
    if (stateSelectedFilters.mainCategory?.id) {
      axios
        .get(`/categories`, {
          params: {
            mainCategoryId: stateSelectedFilters.mainCategory?.id,
          },
        })
        .then((res) => {
          setCategories(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [stateSelectedFilters?.mainCategory?.id]);

  // get sub categories when category is selected
  useEffect(() => {
    if (stateSelectedFilters.category?.id) {
      axios
        .get(`/sub-categories`, {
          params: {
            categoryId: stateSelectedFilters.category?.id,
          },
        })
        .then((res) => {
          setSubCategories(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // get sub category brands when sub category is selected
    if (stateSelectedFilters.subCategory?.id) {
      axios
        .get(`/sub-category-brands`, {
          params: {
            subCategoryId: stateSelectedFilters.subCategory?.id,
          },
        })
        .then((res) => {
          setSubCategoryBrands(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    stateSelectedFilters?.category?.id,
    stateSelectedFilters?.subCategory?.id,
  ]);

  const applyFilters = () => {
    setFilters({
      ...filters,
      name: stateSelectedFilters.name,
      mainCategory: stateSelectedFilters.mainCategory.name || "",
      category: stateSelectedFilters.category.name || "",
      subCategory: stateSelectedFilters.subCategory.name || "",
      brand: stateSelectedFilters.brand.name || "",
    });

    fetchProducts({
      ...filters,
      name: stateSelectedFilters.name || "",
      mainCategory: stateSelectedFilters.mainCategory.name || "",
      category: stateSelectedFilters.category.name || "",
      subCategory: stateSelectedFilters.subCategory.name || "",
      brand: stateSelectedFilters.brand.name || "",
    });
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side={isMobile ? "bottom" : "right"}
        className="rounded-lg bg-white p-4 md:m-4"
      >
        <SheetHeader>
          <SheetTitle>{t("filter.filter")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Label>{t("filter.productName")}</Label>
            <Input
              value={stateSelectedFilters.name}
              onChange={(e) => handleChangeName(e)}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label>{t("filter.mainCategory")}</Label>
            <Select
              onValueChange={(value) => {
                handleSelectMainCategory(value);
              }}
              disabled={mainCategories.length === 0}
              defaultValue={stateSelectedFilters.mainCategory?.id || ""}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {mainCategoryOptions.map((category, index) => {
                  return (
                    <SelectItem key={index} value={category.value}>
                      {category.label}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-2">
            <Label>{t("filter.category")}</Label>
            <Select
              onValueChange={(value) => {
                handleSelectCategory(value);
              }}
              disabled={categories.length === 0}
              defaultValue={stateSelectedFilters.category?.id || ""}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {categoryOptions.map((category, index) => {
                  return (
                    <SelectItem key={index} value={category.value}>
                      {category.label}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-2">
            <Label>{t("filter.subCategory")}</Label>
            <Select
              onValueChange={(value) => {
                handleSelectSubCategory(value);
              }}
              disabled={subCategories.length === 0}
              defaultValue={stateSelectedFilters.subCategory?.id || ""}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {subCategoryOptions.map((category, index) => {
                  return (
                    <SelectItem key={index} value={category.value}>
                      {category.label}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-2">
            <Label>{t("filter.brand")}</Label>
            <Select
              onValueChange={(value) => {
                handleSelectBrand(value);
              }}
              disabled={subCategoryBrands.length === 0}
              defaultValue={stateSelectedFilters.brand?.id || ""}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {subCategoryBrandOptions.map((category, index) => {
                  return (
                    <SelectItem key={index} value={category.value}>
                      {category.label}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
        </div>
        <SheetFooter className="mt-4">
          <Button
            onClick={() => {
              applyFilters();
              onOpenChange();
            }}
          >
            {t("common.save")}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import BottomNav from "../BottomNav";

import LogoutDialog from "@/components/Auth/LogoutDialog";

import { ReactComponent as AccountIcon } from "@/assets/svgs/accounticon.svg";
import { ReactComponent as EgarantiLogo } from "@/assets/svgs/egarantilogo.svg";
import { ReactComponent as HomeIcon } from "@/assets/svgs/homeicon.svg";
import { ReactComponent as LayoutGridIcon } from "@/assets/svgs/layout-grid.svg";
import { ReactComponent as LogoutIcon } from "@/assets/svgs/logouticon.svg";
import { ReactComponent as ArrowIcon } from "@/assets/svgs/sub-menu-icon.svg";
import { ReactComponent as CheckVerifiedIcon } from "@/assets/svgs/verifiedicon.svg";

import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

export default function SideNav() {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [onOpenLogout, setOnOpenLogout] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const menuList = [
    {
      icon: <HomeIcon />,
      title: t("navbar.home"),
      link: "/",
      subMenu: [],
    },
    {
      icon: <LayoutGridIcon />,
      title: t("navbar.products"),
      link: "/products",
      subMenu: [],
    },
    {
      icon: <CheckVerifiedIcon />,
      title: t("navbar.services"),
      link: "/services",
      subMenu: [
        { title: t("navbar.service1"), link: "/services" },
        { title: t("navbar.service2"), link: "/claims/service" },
        { title: t("navbar.service3"), link: "/claims/setup" },
      ],
    },
    {
      icon: <AccountIcon />,
      title: t("navbar.account"),
      link: "/account",
      subMenu: [],
    },
  ];

  const activeClassNames =
    "text-[#002573] stroke-[#0049E6] bg-[#CCDBFA] text-[#002573] flex items-center gap-[15px] rounded-lg px-[19px] py-[15px]";

  const subMenuActiveClassNames =
    "text-[#002573] text-[#0040C9] flex items-center gap-[15px] rounded-lg px-[19px] py-[15px] hover:bg-[#F2F5F9] hover:text-[#111729]";

  const handleSubMenuToggle = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  const getNavLinkClassNames = (item) => {
    const isActive =
      item.link === location.pathname ||
      item.subMenu.some((subItem) => subItem.link === location.pathname);
    return isActive
      ? activeClassNames
      : "flex items-center gap-[15px] rounded-lg px-[19px] py-[15px] hover:bg-[#F2F5F9] hover:text-[#111729]";
  };

  if (!isDesktop) {
    return <BottomNav />;
  } else {
    return (
      <div className="sticky left-0 top-0 z-40 hidden h-screen flex-col p-8 shadow-default md:flex">
        <div className="h-fit w-full border-b border-[#E3E8EF] p-6 pt-0">
          <EgarantiLogo />
        </div>
        <nav className="mt-3 h-full">
          <ul className="relative flex h-full flex-col gap-1">
            {menuList.map((item, index) => (
              <li key={index} className="relative">
                <NavLink
                  to={item.link}
                  className={getNavLinkClassNames(item)}
                  onClick={() => handleSubMenuToggle(index)}
                >
                  {item.icon}
                  <span>{item.title}</span>
                  {item.subMenu.length > 0 && (
                    <ArrowIcon
                      className={`${
                        openSubMenu !== index ? "rotate-180" : ""
                      } ml-auto transform transition-transform`}
                    />
                  )}
                </NavLink>
                {item.subMenu.length > 0 && openSubMenu === index && (
                  <ul className="ml-6 mt-1 flex flex-col gap-1">
                    {item.subMenu.map((subItem, subIndex) => (
                      <NavLink
                        to={subItem.link}
                        key={subIndex}
                        className={({ isActive }) =>
                          isActive
                            ? subMenuActiveClassNames
                            : "flex items-center gap-[15px] rounded-lg px-[19px] py-[15px] hover:bg-[#F2F5F9] hover:text-[#111729]"
                        }
                      >
                        <span>{subItem.title}</span>
                      </NavLink>
                    ))}
                  </ul>
                )}
              </li>
            ))}
            <button
              onClick={() => setOnOpenLogout(true)}
              className="absolute -bottom-5 flex w-full items-center gap-[15px] rounded-lg px-[19px] py-[15px] text-[#677389] hover:bg-[#F2F5F9] hover:text-[#111729]"
            >
              <LogoutIcon />
              <span>{t("navbar.logout")}</span>
            </button>
          </ul>
        </nav>
        <LogoutDialog open={onOpenLogout} onOpenChange={setOnOpenLogout} />
      </div>
    );
  }
}

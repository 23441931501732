import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tag,
} from "@egaranti/components";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import axios from "../../../lib/api/axios";
import { ReactComponent as ClaimsIcon } from "../../../assets/svgs/claimsIcon.svg";
import ClaimsTableHeader from "../../../components/Claims/Service/ClaimsTableHeader";
import EmptyData from "../../../components/common/EmptyData";
import Loading from "../../../components/common/Loading";
import useFetchServiceClaims from "../../../lib/utils/hooks/use-fetch-service-claims";

import AccordionHero from "@/components/Claims/AccordionHero";
import FilterSheet from "@/components/Claims/Service/FilterSheet";

const ServiceHomePage = () => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const [openFilterSheet, setOpenFilterSheet] = useState(false);

  const {
    filters,
    setFilters,
    loading,
    fetchServiceClaims,
    serviceClaims,
    pagination,
  } = useFetchServiceClaims();

  const handleGoToDetailPage = (id) => {
    navigateTo(`/claims/service/${id}`);
  };

  useEffect(() => {
    fetchServiceClaims();
  }, []);

  const STATUS_TYPES = {
    WAITING_MERCHANT: {
      label: t("claimsPage.waitingForAnswerFromCompany"),
      variant: "yellow",
      value: "WAITING_MERCHANT",
    },
    WAITING_CUSTOMER: {
      label: t("claimsPage.waitingForAnswerFromCustomer"),
      variant: "yellow",
      value: "WAITING_CUSTOMER",
    },
    APPROVED: {
      label: t("claimsPage.accepted"),
      variant: "green",
      value: "APPROVED",
    },
    REJECTED: {
      label: t("claimsPage.rejected"),
      variant: "red",
      value: "REJECTED",
    },
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="mb-6 min-h-[78px] w-full">
        <AccordionHero
          logo={<ClaimsIcon className="mx-auto" />}
          triggerText={t("claimsPage.hero.triggerText")}
          heading1={t("claimsPage.hero.heading1")}
          title2={t("claimsPage.hero.title2")}
          title3={t("claimsPage.hero.title3")}
          title4={t("claimsPage.hero.title4")}
          title5={t("claimsPage.hero.title5")}
          title6={t("claimsPage.hero.title6")}
          desc1={t("claimsPage.hero.desc1")}
          desc2={t("claimsPage.hero.desc2")}
          desc3={t("claimsPage.hero.desc3")}
          desc4={t("claimsPage.hero.desc4")}
          desc5={t("claimsPage.hero.desc5")}
          desc6={t("claimsPage.hero.desc6")}
        />
      </div>
      <div className="flex flex-col bg-[#F9FAFB] md:m-5 md:bg-white">
        <ClaimsTableHeader
          setOpenFilterSheet={setOpenFilterSheet}
          filters={filters}
          setFilters={setFilters}
          fetchServiceClaims={fetchServiceClaims}
          STATUS_TYPES={STATUS_TYPES}
        />
        {serviceClaims.length > 0 ? (
          <>
            <Table className="border border-[#EAECF0] bg-white shadow-sm">
              <TableHeader className="rounded-lg border border-[#E3E7EF] bg-[#F9FAFC] font-semibold text-[#364153]">
                <TableRow>
                  <TableHead>{t("claimsPage.no")}</TableHead>
                  <TableHead>{t("claimsPage.productName")}</TableHead>
                  <TableHead>{t("claimsPage.status")}</TableHead>
                  <TableHead>{t("claimsPage.date")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {serviceClaims?.map((claim, index) => (
                  <TableRow
                    onClick={() => handleGoToDetailPage(claim.id)}
                    className="cursor-pointer"
                    key={index}
                  >
                    <TableCell>
                      <span>{claim.id}</span>
                    </TableCell>
                    <TableCell>
                      <span>{claim.individualCustomerProductName}</span>
                    </TableCell>
                    <TableCell>
                      <Tag
                        size="sm"
                        variant={STATUS_TYPES[claim.status]?.variant}
                        className="flex w-max items-center justify-center"
                      >
                        {STATUS_TYPES[claim.status]?.label}
                      </Tag>
                    </TableCell>
                    <TableCell>
                      <span className="flex flex-col items-start justify-start">
                        <span className="text-sm">
                          {claim?.createdAt?.split(" ")[0]}
                        </span>
                        <span className="text-xs text-gray-500">
                          {claim?.createdAt?.split(" ")[1]}
                        </span>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <EmptyData
            title={t("claimsPage.noClaimTitle")}
            description={t("claimsPage.noClaimDesc")}
          />
        )}
        {serviceClaims.length > 0 && (
          <div className="flex w-full flex-col items-center justify-center gap-5 p-5 md:border-t">
            <Pagination
              totalPages={pagination.totalPages}
              currentPage={pagination.page}
              pageSize={pagination.size}
              onPageChange={(i) => {
                console.log("i =>", i);
                setFilters({ ...filters, page: i });
                fetchServiceClaims({ ...pagination, page: i });
              }}
            />
          </div>
        )}
        <FilterSheet
          open={openFilterSheet}
          onOpenChange={setOpenFilterSheet}
          setFilters={setFilters}
          filters={filters}
          fetchServiceClaims={fetchServiceClaims}
        />
      </div>
    </>
  );
};

export default ServiceHomePage;

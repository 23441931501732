import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer({ stickyBottom }) {
  const { t } = useTranslation();
  const footerClasses = stickyBottom
    ? "w-full bg-[#F9FAFC] p-4 sticky bottom-0 w-full px-[72px] py-[23px] md:flex hidden items-center justify-between text-[#677389] font-medium border-t border-[#E3E7EF] mt-auto"
    : "w-full px-[72px] py-[23px] md:flex hidden items-center justify-between text-[#677389] font-medium border-t border-[#E3E7EF]";

  return (
    <footer className={footerClasses}>
      <span className="text-sm">© 2024 egaranti</span>
      <ul className="flex items-center gap-4 text-sm">
        <li>
          <a
            className="font-medium text-[#677389] no-underline"
            href="https://egaranti.com/tr/policy/usage-terms/"
            target="_blank"
          >
            {t("navbar.usageterms")}
          </a>
        </li>
        <li>
          <a
            className="font-medium text-[#677389] no-underline"
            href="https://egaranti.com/tr/sss/"
            target="_blank"
          >
            {t("navbar.faq")}
          </a>
        </li>
        <li>
          <a
            className="font-medium text-[#677389] no-underline"
            href="https://egaranti.com/tr/contact/"
            target="_blank"
          >
            {t("navbar.contact")}
          </a>
        </li>
      </ul>
    </footer>
  );
}

import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@egaranti/components";

import React, { useEffect, useState } from "react";

import { Check } from "lucide-react";

import { getProducts } from "@/lib/api/product";
import { cn } from "@/lib/utils";
import useDebounce from "@/lib/utils/hooks/useDebounce";

export function SelectProductName({ value, setValue }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (open) {
      setSearch("");
      setLoading(true);
      getProducts({
        query: "",
        page: 1,
        size: 10,
      }).then((res) => {
        setData(
          res.data.map((product) => ({
            label: product.name,
            value: product.name,
          })),
        );
        setLoading(false);
      });
    }
  }, [open]);

  const onChangeSearch = (event) => {
    setSearch(event);
    handleSearch(event);
  };

  const handleSearch = useDebounce((e) => {
    setLoading(true);
    getProducts({
      page: 1,
      size: 10,
      query: e,
    })
      .then((res) => {
        setData(
          res.data.content.map((product) => ({
            label: product.name,
            value: product.name,
          })),
        );
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, 750);

  let buttonLabel = "";
  if (value) {
    buttonLabel =
      data.find((product) => product.value === value)?.label || value;

    if (buttonLabel?.length > 24) {
      buttonLabel = buttonLabel.slice(0, 24) + "...";
    }
  }

  const handleSelect = (selectedValue) => {
    setValue(selectedValue);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="ring-offset-background focus:ring-ring flex w-full items-center justify-between rounded-lg border border-[#CDD5E0] px-4 py-6 text-[#111729] placeholder:text-[#677389] focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 [&>span]:line-clamp-1"
        >
          {buttonLabel || "Ürün seçin"}
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="#677389"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.667"
              d="M5 7.5l5 5 5-5"
            ></path>
          </svg>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full min-w-[350px] p-0">
        <Command shouldFilter={false} className="overflow-visible">
          <CommandInput
            value={search}
            onValueChange={onChangeSearch}
            className="w-full"
            placeholder="Ürün ara"
          />
          <CommandEmpty>Ürün bulunamadı</CommandEmpty>
          <CommandGroup className="h-full overflow-auto">
            {data?.map((product) => (
              <CommandItem
                onSelect={() => handleSelect(product.value)}
                key={product.value}
                value={product.value}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === product.value ? "opacity-100" : "opacity-0",
                  )}
                />
                {product.label.length > 24
                  ? product.label.slice(0, 24) + "..."
                  : product.label}
              </CommandItem>
            ))}
            {search && !data.some((product) => product.label === search) && (
              <CommandItem onSelect={() => handleSelect(search)} value={search}>
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === search ? "opacity-100" : "opacity-0",
                  )}
                />
                {'"' + search + '" olarak seç'}
              </CommandItem>
            )}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default SelectProductName;

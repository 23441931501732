import { useEffect, useState } from "react";

import EmptyData from "../common/EmptyData";
import ProductCard from "./ProductCard";
import VerifiedProductDetailSheet from "./VerifiedProductDetailSheet";

import { motion } from "framer-motion";
import { t } from "i18next";

function VerifiedProductsList({ verifiedProducts, fetchProducts }) {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [openDetailSheet, setOpenDetailSheet] = useState(false);

  const handleDetailModal = (product) => {
    setSelectedProduct(product);
    setOpenDetailSheet(true);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="flex w-full flex-col">
      <h2 className="mb-4 text-sm font-medium text-gray-500 md:text-base md:text-gray-800">
        {t("productsPage.approvedProducts")}
      </h2>
      {verifiedProducts.length > 0 ? (
        <motion.div className="grid grid-cols-1 items-start gap-5 md:grid-cols-3">
          {verifiedProducts.map((product) => (
            <ProductCard
              product={product}
              onClick={() => handleDetailModal(product)}
              key={product.id}
            />
          ))}
        </motion.div>
      ) : (
        <EmptyData
          className="mx-auto max-w-[400px]"
          title={t("productsPage.noData")}
          description={t("productsPage.noDataDesc")}
        ></EmptyData>
      )}

      <VerifiedProductDetailSheet
        selectedProduct={selectedProduct}
        open={openDetailSheet}
        onOpenChange={setOpenDetailSheet}
      />
    </div>
  );
}
export default VerifiedProductsList;

import ProductIcon from "../ProductIcon";

const ProductDetails = ({ product }) => (
  <div className="flex flex-col items-center justify-center border-b pb-8 pt-2">
    <ProductIcon productType={product.category} />
    <div className="mt-3 flex flex-col gap-2">
      <h2 className="text-center text-base font-medium text-[#677389]">
        {product.category}
      </h2>
      <h2 className="text-center text-xl font-semibold">{product.name}</h2>
    </div>
  </div>
);

export default ProductDetails;

import { useEffect, useState } from "react";

import ProductListHeader from "../../components/Products/ProductListHeader";
import UnVerifiedProductsList from "../../components/Products/UnVerifiedProductsList";
import VerifiedProductsList from "../../components/Products/VerifiedProductsList";
import useFetchVerifiedProduct from "../../lib/utils/hooks/use-fetch-verified-product";

//import AddProductSheet from "@/components/Products/AddProductSheet";
import FilterSheet from "@/components/Products/FilterSheet";
import AddNewProductSheet from "@/components/newaddproduct/ProductForm";

export default function Products() {
  const {
    filters,
    setFilters,
    fetchProducts,
    verifiedProducts,
    unVerifiedProducts,
  } = useFetchVerifiedProduct();

  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [openAddProductSheet, setOpenAddProductSheet] = useState(false);

  const [stateSelectedFilters, setStateSelectedFilters] = useState({
    name: "",
    mainCategory: {},
    category: {},
    subCategory: {},
    subCategoryBrand: {},
    brand: "",
  });

  useEffect(() => {
    fetchProducts();
  }, [openAddProductSheet]);

  return (
    <div className="p-5 pb-[100px] md:pb-8">
      <div className="flex w-full flex-col items-center justify-center gap-y-4 md:gap-y-0">
        <div className="flex w-full flex-col items-center justify-center">
          <ProductListHeader
            filters={filters}
            setFilters={setFilters}
            fetchProducts={fetchProducts}
            stateSelectedFilters={stateSelectedFilters}
            setStateSelectedFilters={setStateSelectedFilters}
            setOpenFilterSheet={setOpenFilterSheet}
            setOpenAddProductSheet={setOpenAddProductSheet}
          />
          <div className="mt-8 flex w-full flex-col gap-8 rounded-lg md:bg-white md:p-5 md:shadow-sm">
            <VerifiedProductsList
              verifiedProducts={verifiedProducts}
              setFilters={setFilters}
              filters={filters}
              fetchProducts={fetchProducts}
              stateSelectedFilters={stateSelectedFilters}
              setStateSelectedFilters={setStateSelectedFilters}
            />
            <hr className="h-px w-full bg-[#CDD5E0]" />
            <UnVerifiedProductsList
              unVerifiedProducts={unVerifiedProducts}
              fetchProducts={fetchProducts}
              setOpenAddProductSheet={setOpenAddProductSheet}
            />
          </div>
        </div>
      </div>
      {/* <AddProductSheet
        open={openAddProductSheet}
        onOpenChange={setOpenAddProductSheet}
      /> */}
      <AddNewProductSheet
        open={openAddProductSheet}
        onOpenChange={setOpenAddProductSheet}
      />
      <FilterSheet
        fetchProducts={fetchProducts}
        setFilters={setFilters}
        filters={filters}
        open={openFilterSheet}
        onOpenChange={setOpenFilterSheet}
        stateSelectedFilters={stateSelectedFilters}
        setStateSelectedFilters={setStateSelectedFilters}
      />
    </div>
  );
}

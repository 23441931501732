import React from "react";

import HomePageBanner from "@/components/Services/HomePageBanner";
import MyServices from "@/components/Services/MyServices";
import Opportunities from "@/components/Services/Opportunities";
import PhoneInsurances from "@/components/Services/PhoneInsurances";

const Services = () => {
  return (
    <div className="">
      <main className="flex min-h-screen flex-col gap-6 p-6 md:bg-[#F9FAFC]">
        <HomePageBanner />
        <MyServices />
        <Opportunities />
        <PhoneInsurances />
      </main>
    </div>
  );
};

export default Services;

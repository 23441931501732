import { Button, useToast } from "@egaranti/components";

import React, { useState } from "react";

import ExtendedWarrantyModal from "./ExtendedWarrantyModal";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/common/shared/Carousel";

import ThiefIcon from "@/assets/images/overview/thieft.png";
import { ReactComponent as PhoneIcon } from "@/assets/svgs/phone.svg";

const Opportunities = () => {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-lg font-semibold text-[#364153]">Fırsatlar</h2>
      <Carrousel />
    </div>
  );
};

export default Opportunities;

const Carrousel = () => {
  const [isSent, setIsSent] = useState(false);
  const { toast } = useToast();
  const content = [
    {
      id: 0,
      title: "Uzatılmış garanti satın al, telefonunun ömrünü uzat 🛡",
      buttonTitle: <ExtendedWarrantyModal />,
      svg: <PhoneIcon />,
    },
    {
      id: 1,
      title:
        "Telefonuna uzatılmış garanti yaptır, hırsızlığa karşı önlemini al!",
      buttonTitle: isSent ? "Talebini Aldık!" : "Gelince Haber Ver",
      image: ThiefIcon,
      action: () => {
        setIsSent(true);
        toast({
          variant: "success",
          title: "Talebini Aldık!",
          description: "Paket geldiğinde size haber vereceğiz.",
        });
      },
    },
  ];

  return (
    <Carousel>
      <CarouselContent className="gap-6">
        {/* // gap */}
        {content.map((item) => (
          <CarouselItem className="md:basis-1/3">
            <div className="flex h-full rounded-lg bg-white">
              <div className="relative flex w-3/4 flex-col items-center justify-center gap-4 rounded-lg bg-[#0067AB] px-2 py-10">
                <span className="absolute bottom-1 left-2 flex items-center gap-1 text-[10px] text-white">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.6666 6.12499V3.96666C11.6666 2.98656 11.6666 2.49652 11.4759 2.12217C11.3081 1.79289 11.0404 1.52517 10.7111 1.3574C10.3368 1.16666 9.84674 1.16666 8.86665 1.16666H5.13331C4.15322 1.16666 3.66318 1.16666 3.28883 1.3574C2.95955 1.52517 2.69183 1.79289 2.52405 2.12217C2.33331 2.49652 2.33331 2.98656 2.33331 3.96666V10.0333C2.33331 11.0134 2.33331 11.5035 2.52405 11.8778C2.69183 12.2071 2.95955 12.4748 3.28883 12.6426C3.66318 12.8333 4.15322 12.8333 5.13331 12.8333H6.99998M10.5 12.25V8.74999M8.74998 10.5H12.25"
                      stroke="white"
                      stroke-width="1.12"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Uzatılmış Garanti
                </span>
                <h1 className="text-center text-sm font-semibold text-white">
                  {item.title}
                </h1>
                <Button
                  disabled={isSent && item.id === 1}
                  onClick={item.action}
                  size="sm"
                  className="text-xs md:text-sm"
                  variant="secondaryGray"
                >
                  {item.buttonTitle}
                </Button>
              </div>
              <div
                aria-hidden="true"
                className="flex w-2/4 items-center justify-center"
              >
                {item.svg && item.svg}
                {item.image && (
                  <img
                    className="w-30 object-contain"
                    src={item.image}
                    alt=""
                  />
                )}
              </div>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
};

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import React, { forwardRef } from "react";

import InputBox from "./InputBox";

const PriceInputPrice = forwardRef(
  ({ value, onChange, placeholder, onChangeUnit, valueUnit }, ref) => {
    const formatNumberWithCommas = (number) =>
      number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    const handleChange = (event) => {
      const enteredValue = event.target.value.replace(/\./g, "").trim(); // Remove dots
      const numericValue = parseInt(enteredValue, 10);

      if (enteredValue === "") {
        onChange(null);
      } else if (!isNaN(numericValue)) {
        onChange(numericValue);
      }
    };

    return (
      <div className="flex h-full w-full items-center">
        <CurrencySelect valueUnit={valueUnit} onChangeUnit={onChangeUnit} />
        <InputBox
          ref={ref}
          type="number"
          value={formatNumberWithCommas(value)}
          onChange={handleChange}
          placeholder={placeholder}
          className="rounded-l-none border-l-0"
        />
      </div>
    );
  },
);

const CurrencySelect = ({ valueUnit, onChangeUnit }) => {
  const options = [
    { value: "TRY", label: "₺" },
    { value: "USD", label: "$" },
    { value: "EUR", label: "€" },
    { value: "GBP", label: "£" },
  ];

  return (
    <DropdownMenu.Root value={valueUnit} onValueChange={onChangeUnit}>
      <DropdownMenu.Trigger asChild>
        <button className="h-full" aria-label="Para birimi seçin">
          <div className="flex items-center justify-center rounded-md rounded-r-none border border-r-0 border-gray-300 p-4">
            <span className="text-gray-700">
              {options.find((option) => option.value === valueUnit)?.label}
            </span>
          </div>
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        side="top"
        sideOffset={2}
        className="max-w-20 rounded-md border bg-white p-2 shadow"
      >
        <DropdownMenu.Group>
          {options.map((option) => (
            <DropdownMenu.Item
              key={option.value}
              value={option.value}
              onSelect={() => onChangeUnit(option.value)}
              className="flex cursor-pointer items-center rounded-md p-2 hover:bg-gray-100"
            >
              <span className="text-gray-700">{option.label}</span>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Group>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default PriceInputPrice;

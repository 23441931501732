import * as yup from "yup";

export const addressSchema = yup.object({
  title: yup.string().required("Adres başlığı boş bırakılamaz"),
  firstName: yup.string().required("Ad boş bırakılamaz"),
  lastName: yup.string().required("Soyad boş bırakılamaz"),
  addressLine: yup.string().required("Adres boş bırakılamaz"),
  cityId: yup.string().required("Şehir boş bırakılamaz"),
  districtId: yup.string().required("İlçe boş bırakılamaz"),
  postCode: yup.string().required("Posta kodu boş bırakılamaz"),
});
export const addressEditSchema = yup.object({
  title: yup.string().required("Adres başlığı boş bırakılamaz"),
  firstName: yup.string().required("Ad boş bırakılamaz"),
  lastName: yup.string().required("Soyad boş bırakılamaz"),
  addressLine: yup.string().required("Adres boş bırakılamaz"),
  city: yup.object().required("Şehir boş bırakılamaz"),
  district: yup.object().required("İlçe boş bırakılamaz"),
  postCode: yup.string().required("Posta kodu boş bırakılamaz"),
});

import "@egaranti/components";
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  useToast,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PreviewClaimDialog from "./PreviewClaimDialog";

import { DatePicker } from "@/components/common/shared/datePicker";
import InfoTooltip from "@/components/common/shared/infoTooltip";

import { ReactComponent as InfoIcon } from "@/assets/svgs/infoemptyicon.svg";

import moment from "moment/moment";
import * as yup from "yup";

import axios from "@/lib/api/axios";
import { parseError } from "@/lib/utils";
import DialCodes from "@/lib/utils/data/dialCodes";

const CreateForm = ({ setStep, formData: oldFormData, selectedProduct }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const { toast } = useToast();

  const [open, setOpen] = useState(false);

  const [addresses, setAddresses] = useState([]);

  const schema = yup.object().shape({
    phone: yup.string().required("Telefon numarası zorunludur."),
    individualCustomerAddressId: yup.string().required("Adres zorunludur."),
    customerNote: yup.string().required("Not zorunludur."),
    // at least one will be true morning, afternoon, evening in availableDates array
    // with .addMethod
    availableDates: yup.array().of(
      yup
        .object()
        .shape({
          morning: yup.boolean(),
          afternoon: yup.boolean(),
          evening: yup.boolean(),
        })
        .test(
          "at-least-one",
          "En az bir seçim yapmalısınız.",
          (value) => value.morning || value.afternoon || value.evening,
        ),
    ),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      productName: oldFormData.productName,
      individualCustomerProductId: oldFormData.individualCustomerProductId,
      phone: "",
      countryCode: "TR",
      individualCustomerAddressId: "",
      customerNote: "",
      availableDates: [
        {
          id: 1,
          day: null,
          morning: false,
          afternoon: false,
          evening: false,
        },
        {
          id: 2,
          day: null,
          morning: false,
          afternoon: false,
          evening: false,
        },
        {
          id: 3,
          day: null,
          morning: false,
          afternoon: false,
          evening: false,
        },
      ],
    },
    shouldUnregister: false,
  });

  const getAllAddresses = () => {
    axios
      .get("/individual-customer-addresses")
      .then((res) => {
        setAddresses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllAddresses();
  }, []);

  const handleCreateClaim = () => {
    const formData = form.getValues();

    const payload = {
      individualCustomerProductId: formData.individualCustomerProductId,
      phone: formData.phone,
      countryCode: formData.countryCode,
      individualCustomerAddressId: formData.individualCustomerAddressId,
      customerNote: formData.customerNote,
      availableDateMap: form.getValues("availableDates").reduce((acc, item) => {
        const dateString = moment(item.day).format("YYYY-MM-DD");
        acc[dateString] = [];

        if (item.morning || item.afternoon || item.evening) {
          const dateString = moment(item.day).format("YYYY-MM-DD");

          if (item.morning) {
            acc[dateString].push("MORNING");
          }
          if (item.afternoon) {
            acc[dateString].push("AFTERNOON");
          }
          if (item.evening) {
            acc[dateString].push("EVENING");
          }
        }
        return acc;
      }, {}),
    };

    axios
      .post(`/individual-customer-product-setup-claims`, payload)
      .then((res) => {
        toast({
          description: t("Kurulum talebi gönderildi."),
          variant: "success",
        });
        navigateTo("/claims/setup");
      })
      .catch((err) => {
        const errorText = parseError(err.response.data.errors);
        toast({
          description: errorText,
          variant: "error",
        });
      });
  };

  const handlePreviewClaim = () => {
    form.trigger().then((isValid) => {
      if (isValid) {
        setOpen(true);
      }
    });
  };

  return (
    <div className="flex w-full flex-col">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handlePreviewClaim)}>
          <div
            id="product-list"
            className="min-h-[calc(100vh_-_64px)] w-full bg-[#F9FAFC] p-4"
          >
            <div className="mx-auto mt-6 flex max-w-[1000px] flex-col gap-5 rounded-lg border border-[#EAECF0] bg-white p-6">
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                <div className="flex gap-2">
                  <div className="w-full max-w-[110px]">
                    <FormField
                      control={form.control}
                      name="countryCode"
                      render={({ field }) => (
                        <FormItem>
                          <Label>{t("claimsPage.countryCode")}</Label>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {DialCodes.map((item, index) => (
                                <SelectItem key={index} value={item.value}>
                                  {item.icon} {item.countryCode}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <Label>{t("claimsPage.phoneNumber")}</Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                {addresses.length > 0 ? (
                  <FormField
                    control={form.control}
                    name="individualCustomerAddressId"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("claimsPage.address")}</Label>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue
                                placeholder={t("claimsPage.address")}
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {addresses.map((address) => (
                              <SelectItem
                                key={address.id}
                                value={String(address.id)}
                              >
                                {address.title}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : (
                  <div className="flex flex-col items-center gap-4 p-4">
                    <InfoIcon className="w-12" />
                    <p className="text-center text-sm font-medium text-[#364153]">
                      {t("claimsPage.noAddress")}
                    </p>
                    <Button onClick={() => navigateTo("/account/address")}>
                      {t("claimsPage.addAddress")}
                    </Button>
                  </div>
                )}
              </div>
              {/* DATE */}
              <div className="flex flex-col justify-between gap-4">
                <h2 className="flex items-center gap-1 text-sm font-medium text-black">
                  {t("claimsPage.selectDate")}
                  <InfoTooltip>{t("claimsPage.dateSelectionInfo")}</InfoTooltip>
                </h2>
                <div className="flex w-full flex-col gap-8">
                  {form.watch("availableDates").map((date, index) => (
                    <div className="flex flex-col justify-between gap-4 md:flex-row md:gap-4 ">
                      <div className="w-full">
                        <FormField
                          control={form.control}
                          name={`availableDates[${index}].day`}
                          render={({ field }) => (
                            <FormItem className="flex flex-col gap-2">
                              <Label>{t("claimsPage.availableDate")}</Label>
                              <FormControl>
                                <DatePicker
                                  selectedDate={field.value}
                                  onDateChange={field.onChange}
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                      <div className="mb-2 flex w-full flex-col justify-between gap-4 px-2 sm:flex-row">
                        <FormField
                          control={form.control}
                          name={`availableDates[${index}].morning`}
                          render={({ field }) => (
                            <FormItem className="flex shrink-0 items-center gap-1">
                              <FormControl>
                                <Checkbox
                                  className="mt-2"
                                  {...field}
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                              <Label>08.00 - 12.00</Label>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name={`availableDates[${index}].afternoon`}
                          render={({ field }) => (
                            <FormItem className="flex shrink-0 items-center gap-1">
                              <FormControl>
                                <Checkbox
                                  className="mt-2"
                                  {...field}
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                              <Label>12.00 - 16.00</Label>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name={`availableDates[${index}].evening`}
                          render={({ field }) => (
                            <FormItem className="flex shrink-0 items-center gap-1">
                              <FormControl>
                                <Checkbox
                                  className="mt-2"
                                  {...field}
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                              <Label>16.00 - 20.00</Label>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <FormField
                control={form.control}
                name="customerNote"
                render={({ field }) => (
                  <FormItem>
                    <Label>{t("claimsPage.explanation")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mx-auto mt-4 flex max-w-[1000px] justify-end">
              <Button onClick={form.handleSubmit(handlePreviewClaim)}>
                {t("claimsPage.createClaim")}
              </Button>
            </div>
          </div>
        </form>
      </Form>
      {/* <PreviewClaim
        ModalHook={PreviewModalHook}
        handleCreateClaim={handleCreateClaim}
        formData={form.getValues()}
        selectedProduct={selectedProduct}
        availableDates={form.getValues("availableDates")}
      /> */}
      <PreviewClaimDialog
        open={open}
        onOpenChange={() => setOpen(!open)}
        handleCreateClaim={handleCreateClaim}
        formData={form.getValues()}
        selectedProduct={selectedProduct}
        availableDates={form.getValues("availableDates")}
      />
    </div>
  );
};

export default CreateForm;

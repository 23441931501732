import React from "react";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";

// Reusable Insurance Option Component
const InsuranceOption = ({
  to,
  icon: Icon,
  title,
  description,
  comingSoon,
}) => (
  <div
    className={`relative flex h-full flex-col items-center justify-center gap-5 rounded-lg border border-gray-200 p-4 md:h-[240px] md:w-[240px] ${comingSoon ? "cursor-default bg-[#F9FAFC] md:bg-transparent" : "bg-white"}`}
  >
    {comingSoon && (
      <span className="absolute -right-5 -top-3 rounded-full bg-[#E3E7EF] px-2 py-1 text-xs font-medium text-[#111729]">
        Yakında 🔜
      </span>
    )}
    <Icon />
    <h3 className="text-center text-lg font-semibold text-[#364153]">
      {title}
    </h3>
    <p className="text-center text-sm text-[#677389]">{description}</p>
  </div>
);

const PhoneInsurances = () => {
  const insuranceOptions = [
    {
      to: "/services/get-extended-warranty/step1",
      icon: Icon1,
      title: "Uzatılmış Garanti",
      description:
        "Telefonlarınızın garantisini 1,2 ya da 3 yıl uzatabilirsiniz.",
      comingSoon: false,
    },
    {
      icon: Icon2,
      title: "Hırsızlık Koruma Paketi",
      description: "Kapkaç ve evden çalınmalara karşı koruma sağlar.",
      comingSoon: true,
    },
    {
      icon: Icon3,
      title: "Ekran Kırılması Koruma Paketi",
      description: "Telefonun ekranının kırılmasına karşı koruma sağlar.",
      comingSoon: true,
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-lg font-semibold text-[#364153]">
        Cep Telefonu Paketleri
      </h2>
      <div className="grid grid-cols-2 gap-4 md:flex">
        {insuranceOptions.map((option, index) =>
          option.comingSoon ? (
            <InsuranceOption key={index} {...option} />
          ) : (
            <Link to={option.to} key={index} className="relative">
              <InsuranceOption {...option} />
            </Link>
          ),
        )}
      </div>
    </div>
  );
};

export default PhoneInsurances;

const Icon1 = () => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 25 25"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <motion.path
        stroke="#0049E6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.222"
        d="M14.5 2.7v4.13c0 .56 0 .84.109 1.054a1 1 0 00.437.437c.214.109.494.109 1.054.109h4.13m-10.73 8l2 2 4.5-4.5m-1.5-11.5H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.31C4.5 4.71 4.5 5.55 4.5 7.23v10.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.31c.642.328 1.482.328 3.162.328h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311c.327-.642.327-1.482.327-3.162v-9.2l-6-6z"
        initial={{ pathLength: 0, stroke: "gray" }}
        animate={{ pathLength: 1, stroke: "#0049E6" }}
        transition={{ duration: 3, ease: "easeInOut", repeat: Infinity }}
      ></motion.path>
    </motion.svg>
  );
};
const Icon2 = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6138 37.0608C11.6492 37.0012 11.6855 36.9418 11.7227 36.8828C14.4317 32.5763 15.9999 27.4836 15.9999 22.0001C15.9999 17.5818 19.5816 14.0001 23.9999 14.0001C28.4182 14.0001 31.9999 17.5818 31.9999 22.0001C31.9999 24.0341 31.8614 26.0372 31.5933 28.0001M27.3585 41.6873C28.5819 39.2453 29.5849 36.6738 30.3415 34.0001M38.0194 36.264C39.3094 31.7314 40.0001 26.9464 40.0001 22.0001C40.0001 13.1635 32.8366 6.00006 24.0001 6.00006C21.0858 6.00006 18.3534 6.77922 16.0001 8.14058M6.00006 30.7283C7.28138 28.0908 8.00006 25.1294 8.00006 22.0001C8.00006 19.0858 8.77922 16.3534 10.1406 14.0001M23.9998 22.0001C23.9998 29.0345 21.9822 35.5976 18.4942 41.1425"
        stroke="#97A2B6"
        stroke-width="3.6036"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const Icon3 = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 34.9999H24.02M16.4 43.9999H31.6C33.8402 43.9999 34.9603 43.9999 35.816 43.564C36.5686 43.1805 37.1805 42.5686 37.564 41.8159C38 40.9603 38 39.8401 38 37.5999V10.3999C38 8.15973 38 7.03962 37.564 6.18398C37.1805 5.43133 36.5686 4.81941 35.816 4.43591C34.9603 3.99994 33.8402 3.99994 31.6 3.99994H16.4C14.1598 3.99994 13.0397 3.99994 12.184 4.43591C11.4314 4.81941 10.8195 5.43133 10.436 6.18398C10 7.03962 10 8.15973 10 10.3999V37.5999C10 39.8401 10 40.9603 10.436 41.8159C10.8195 42.5686 11.4314 43.1805 12.184 43.564C13.0397 43.9999 14.1598 43.9999 16.4 43.9999ZM25 34.9999C25 35.5522 24.5523 35.9999 24 35.9999C23.4477 35.9999 23 35.5522 23 34.9999C23 34.4477 23.4477 33.9999 24 33.9999C24.5523 33.9999 25 34.4477 25 34.9999Z"
        stroke="#97A2B6"
        stroke-width="3.6036"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getAddress } from "../../../lib/api/address";

import Breadcrumb, { BreadcrumbItem } from "@/components/common/shared/breadcrumbs";

import { addressSchema } from "@/lib/utils/validation/schemas";

export default function AddressDetail() {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  let addressId = window.location.pathname.split("/")[3];
  const [title, setTitle] = useState("");

  const form = useForm({
    resolver: yupResolver(addressSchema),
    defaultValues: {
      title: "",
      firstName: "",
      lastName: "",
      addressLine: "",
      cityId: "",
      districtId: "",
      postCcode: "",
    },
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    getAddress(addressId).then((res) => {
      form.reset(res.data);
      setTitle(res.data.title);
      form.setValue("city", res.data.city?.name);
      form.setValue("district", res.data.district?.name);
    });
  }, []);

  return (
    <div className="flex w-full flex-col">
      <Breadcrumb>
        <BreadcrumbItem link="/account/address">
          {" "}
          {t("accountPage.myAddresses")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("accountPage.addressInfo")} - {title}
        </BreadcrumbItem>
      </Breadcrumb>
      <Form {...form}>
        <form className="w-full bg-[#F9FAFC] p-4">
          <div className="mx-auto mt-6 flex max-w-[1000px] flex-col gap-5 rounded-lg border border-[#EAECF0] bg-white p-[30px]">
            <h2 className="mb-2 font-medium">{t("Adres Bilgileri")}</h2>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("accountPage.title")}</Label>
                  <FormControl>
                    <Input disabled {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col items-center gap-6 md:flex-row">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("accountPage.name")}</Label>
                    <FormControl>
                      <Input disabled {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("accountPage.surname")}</Label>
                    <FormControl>
                      <Input disabled {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("accountPage.province")}</Label>
                  <Input disabled {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="district"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("accountPage.district")}</Label>
                  <Input disabled {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="addressLine"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("accountPage.address")}</Label>
                  <FormControl>
                    <Input disabled {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="w-1/2">
              <FormField
                control={form.control}
                name="postCode"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("accountPage.postCode")}</Label>
                    <FormControl>
                      <Input disabled {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex justify-end gap-4">
              <Button
                onClick={() => {
                  navigateTo("/account/address");
                }}
                variant="secondaryGray"
              >
                {t("accountPage.goBack")}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}

const WarrantyDetails = ({ product, t }) => (
  <div className="mt-8 flex w-full flex-col items-center justify-center border-b px-6 pb-8">
    <div className="flex w-full justify-between">
      <h3 className="text-base font-bold uppercase text-[#677389]">
        {t("productsPage.warrantyPeriod")}
      </h3>
      <div className="flex items-center gap-2">
        <span className="flex shrink-0 items-center gap-1 rounded-xl bg-[#CCDBFA] px-3 py-2 text-center text-xs font-medium text-[#002573]">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.99996 7.66691L7.33329 9.00024L10.3333 6.00024M13.3333 8.00024C13.3333 11.2725 9.76399 13.6525 8.46529 14.4102C8.31769 14.4963 8.24389 14.5393 8.13975 14.5617C8.05892 14.579 7.941 14.579 7.86017 14.5617C7.75602 14.5393 7.68223 14.4963 7.53463 14.4102C6.23593 13.6525 2.66663 11.2725 2.66663 8.00024V4.81197C2.66663 4.27897 2.66663 4.01246 2.7538 3.78338C2.83081 3.581 2.95595 3.40042 3.1184 3.25726C3.30229 3.0952 3.55182 3.00162 4.0509 2.81447L7.62543 1.47402C7.76402 1.42205 7.83332 1.39606 7.90461 1.38576C7.96785 1.37662 8.03207 1.37662 8.0953 1.38576C8.16659 1.39606 8.23589 1.42205 8.37449 1.47402L11.949 2.81447C12.4481 3.00162 12.6976 3.0952 12.8815 3.25726C13.044 3.40042 13.1691 3.581 13.2461 3.78338C13.3333 4.01246 13.3333 4.27897 13.3333 4.81197V8.00024Z"
              stroke="#002573"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {`${product.remainingWarrantyDay} ${t("productsPage.day")}`}
        </span>
      </div>
    </div>
    <div className="mb-8 mt-4 flex w-full flex-col rounded-lg border border-[#E3E7EF]">
      <div className="flex items-center justify-between rounded-lg rounded-b-none border border-[#F9FAFC] bg-white px-4 py-[26px]">
        <h4 className="text-sm text-[#677389] md:text-[16px]">
          {t("productsPage.warrantyPeriod")}
        </h4>
        <span className="text-right text-sm font-semibold text-[#111729] md:text-[18px]">
          {product.remainingWarrantyDay} {t("productsPage.day")}
        </span>
      </div>
      <div className="flex items-center justify-between rounded-lg rounded-t-none border border-[#F9FAFC] bg-white px-4 py-[26px]">
        <h4 className="text-sm text-[#677389] md:text-[16px]">
          {t("productsPage.brand")}
        </h4>
        <span className="text-right text-sm font-semibold text-[#111729] md:text-[18px]">
          {product.merchantName}
        </span>
      </div>
    </div>
  </div>
);

export default WarrantyDetails;

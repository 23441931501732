import { initReactI18next } from "react-i18next";

import TRANSLATIONS_EN from "../../locales/en.json";
import TRANSLATIONS_RU from "../../locales/ru.json";
import TRANSLATIONS_TR from "../../locales/tr.json";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  tr: { translation: TRANSLATIONS_TR },
  en: { translation: TRANSLATIONS_EN },
  ru: { translation: TRANSLATIONS_RU },
};

const detectionOptions = {
  order: ["localStorage"],
  lookupLocalStorage: "i18nextLng",
  caches: ["localStorage"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "tr",
    resources,
    detection: detectionOptions,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import "@egaranti/components";

import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ReactComponent as ReturnIcon } from "../../assets/svgs/chevron-left.svg";
import ProductCard from "../../components/Claims/Create/ProductCard.jsx";
import ProductList from "../../components/Claims/Create/ProductList";
import ServiceClaimForm from "../../components/Claims/Create/ServiceClaimForm";
import SetupClaimForm from "../../components/Claims/Create/SetupClaimForm";
import Stepper from "../../components/Claims/Create/Stepper.jsx";

import Breadcrumb, { BreadcrumbItem } from "@/components/common/shared/breadcrumbs";

import { t } from "i18next";

const CreateClaim = () => {
  const [step, setStep] = useState(0);
  const navigateTo = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [formData, setFormData] = useState({
    productName: null,
    individualCustomerProductId: "",
    phone: null,
    countryCode: "TR",
    individualCustomerAddressId: null,
    productClaimReasons: [],
    option: "OTHER",
    description: null,
    activeStatus: {
      installed: false,
      active: false,
    },
  });

  // get params in url with react router dom v6
  const [paramsFromUrl] = useSearchParams();

  return (
    <div className="flex min-h-screen w-full flex-col bg-[#F9FAFC]">
      <Breadcrumb>
        <BreadcrumbItem link="/claims/setup">
          {t("claimsPage.myClaims")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("claimsPage.createClaim")}</BreadcrumbItem>
      </Breadcrumb>
      <span className="p-5">
        {step !== 0 && (
          <ReturnIcon
            className="cursor-pointer"
            onClick={() => setStep(step - 1)}
          />
        )}
      </span>
      <main className="flex flex-col items-center gap-12 p-5">
        <Stepper
          setStep={setStep}
          steps={[
            { title: t("claimsPage.productSelection") },
            { title: t("claimsPage.claimSelection") },
            { title: t("claimsPage.claimDetails") },
          ]}
          activeStep={step}
        />
        {step === 0 && (
          <ProductList
            paramsFromUrl={paramsFromUrl}
            setSelectedProduct={setSelectedProduct}
            setFormData={setFormData}
            formData={formData}
            selectedProduct={selectedProduct}
            setStep={setStep}
          />
        )}
        {step === 1 && (
          <SelectClaimType
            paramsFromUrl={paramsFromUrl}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            selectedProduct={selectedProduct}
          />
        )}
        {step === 2 && (
          <>
            {formData.claimType ? (
              formData.claimType === "SERVICE" ? (
                <ServiceClaimForm
                  formData={formData}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  setFormData={setFormData}
                  setStep={setStep}
                />
              ) : (
                <SetupClaimForm
                  formData={formData}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  setFormData={setFormData}
                  setStep={setStep}
                />
              )
            ) : (
              <></>
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default CreateClaim;

const SelectClaimType = ({
  setStep,
  setFormData,
  formData,
  selectedProduct,
  paramsFromUrl,
}) => {
  useEffect(() => {
    if (paramsFromUrl.get("claimType")) {
      setFormData({
        ...formData,
        claimType: paramsFromUrl.get("claimType"),
      });
      setStep(2);
    }
  }, [paramsFromUrl]);

  return (
    <div className="flex w-full max-w-[1000px] flex-col items-center gap-6">
      <ProductCard
        role="button"
        aria-label="Geri Dön"
        onClick={() => setStep(0)}
        selectedProduct={selectedProduct}
      />
      <h2 className="text-center text-lg font-semibold text-[#111729]">
        {t("claimsPage.selectRequestType")}
      </h2>
      <div className="flex w-full flex-col justify-between gap-6 md:flex-row">
        <button
          onClick={() => {
            setFormData({
              ...formData,
              claimType: "SERVICE",
            });
            setStep(2);
          }}
          className="flex w-full cursor-pointer items-center gap-3 rounded-lg border border-[#CDD5E0] bg-white px-6 py-4 shadow-sm hover:border-[#6692F0]"
        >
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.1316 7.63137C15.7356 7.23535 15.5376 7.03735 15.4634 6.80902C15.3981 6.60817 15.3981 6.39183 15.4634 6.19098C15.5376 5.96265 15.7356 5.76465 16.1316 5.36863L18.97 2.53026C18.2168 2.18962 17.3806 2 16.5002 2C13.1865 2 10.5002 4.68629 10.5002 8C10.5002 8.49104 10.5592 8.9683 10.6705 9.42509C10.7896 9.91424 10.8492 10.1588 10.8387 10.3133C10.8276 10.4751 10.8035 10.5612 10.7289 10.7051C10.6576 10.8426 10.5211 10.9791 10.248 11.2522L4.00023 17.5C3.1718 18.3284 3.1718 19.6716 4.00023 20.5C4.82865 21.3284 6.1718 21.3284 7.00023 20.5L13.248 14.2522C13.5211 13.9791 13.6576 13.8426 13.7951 13.7714C13.9391 13.6968 14.0251 13.6727 14.1869 13.6616C14.3414 13.651 14.586 13.7106 15.0751 13.8297C15.5319 13.941 16.0092 14 16.5002 14C19.8139 14 22.5002 11.3137 22.5002 8C22.5002 7.11959 22.3106 6.28347 21.97 5.53026L19.1316 8.36863C18.7356 8.76465 18.5376 8.96265 18.3092 9.03684C18.1084 9.1021 17.8921 9.1021 17.6912 9.03684C17.4629 8.96265 17.2649 8.76465 16.8689 8.36863L16.1316 7.63137Z"
              stroke="#667085"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {t("claimsPage.serviceRequest")}
        </button>
        <button
          disabled={!selectedProduct?.setupRequired}
          onClick={() => {
            setFormData({
              ...formData,
              claimType: "SETUP",
            });
            setStep(2);
          }}
          className="relative flex w-full cursor-pointer items-center gap-3 rounded-lg border border-[#CDD5E0] bg-white px-6 py-4 shadow-sm hover:border-[#6692F0] disabled:cursor-default disabled:opacity-60 disabled:hover:border-[#CDD5E0]"
        >
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.1316 7.63137C15.7356 7.23535 15.5376 7.03735 15.4634 6.80902C15.3981 6.60817 15.3981 6.39183 15.4634 6.19098C15.5376 5.96265 15.7356 5.76465 16.1316 5.36863L18.97 2.53026C18.2168 2.18962 17.3806 2 16.5002 2C13.1865 2 10.5002 4.68629 10.5002 8C10.5002 8.49104 10.5592 8.9683 10.6705 9.42509C10.7896 9.91424 10.8492 10.1588 10.8387 10.3133C10.8276 10.4751 10.8035 10.5612 10.7289 10.7051C10.6576 10.8426 10.5211 10.9791 10.248 11.2522L4.00023 17.5C3.1718 18.3284 3.1718 19.6716 4.00023 20.5C4.82865 21.3284 6.1718 21.3284 7.00023 20.5L13.248 14.2522C13.5211 13.9791 13.6576 13.8426 13.7951 13.7714C13.9391 13.6968 14.0251 13.6727 14.1869 13.6616C14.3414 13.651 14.586 13.7106 15.0751 13.8297C15.5319 13.941 16.0092 14 16.5002 14C19.8139 14 22.5002 11.3137 22.5002 8C22.5002 7.11959 22.3106 6.28347 21.97 5.53026L19.1316 8.36863C18.7356 8.76465 18.5376 8.96265 18.3092 9.03684C18.1084 9.1021 17.8921 9.1021 17.6912 9.03684C17.4629 8.96265 17.2649 8.76465 16.8689 8.36863L16.1316 7.63137Z"
              stroke="#667085"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {t("claimsPage.setupRequest")}
          {!selectedProduct?.setupRequired && (
            <span className="absolute top-16 text-sm">
              {t("claimsPage.setupNotRequired")}
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

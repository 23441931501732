import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";
import { useToast } from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import PriceInputPrice from "../common/PriceInputBox";
import AutoCompleteInput from "./SelectProductName";
import Loading from "./loading";

import PlaceHolderIcon from "@/assets/images/placeholder-icon.png";

import moment from "moment";
import { withMask } from "use-mask-input";
import * as yup from "yup";

import axios from "@/lib/api/axios";
import { parseError } from "@/lib/utils";
import { cn } from "@/lib/utils";
import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

// Validation schema'yı component dışına taşıyalım
const productSchema = yup.object({
  unverifiedCategoryId: yup.string().required("Kategori seçimi zorunludur"),

  productName: yup.string().required("Ürün adı zorunludur"),
  purchaseDate: yup
    .string()
    .required("Satın alma tarihi zorunludur")
    .matches(
      /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[0-2])[/]\d{4}$/,
      "Geçerli bir tarih giriniz (GG/AA/YYYY)",
    ),
  productPrice: yup.number().required("Fiyat zorunludur"),
});

// Form adımlarını enum olarak tanımlayalım
const FORM_STEPS = {
  CATEGORY: 1,
  BRAND: 2,
  DETAILS: 3,
  DONE: 4,
};

const SelectableCard = ({ selected, onClick, item }) => (
  <div className="flex flex-col items-center">
    <div
      className={cn(
        "flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-lg border bg-white p-4",
        selected
          ? "border-2 border-[#0049E6]"
          : "border-gray-200 hover:border-blue-200",
      )}
      onClick={onClick}
    >
      <img
        className="max-w-12"
        src={item.logoUrl || PlaceHolderIcon}
        alt={item.name}
      />
    </div>
    <span
      className={cn(
        "mt-2 text-center text-xs font-semibold",
        selected ? "text-[#0049E6]" : "text-gray-500",
      )}
    >
      {item.name.length > 20 ? item.name.substring(0, 20) + "..." : item.name}
    </span>
  </div>
);

const ProductForm = ({ open, onOpenChange }) => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [step, setStep] = useState(FORM_STEPS.CATEGORY);
  const isMobile = useMediaQuery("(max-width: 640px)");
  const [loading, setLoading] = useState(false);

  const [unverifiedBrands, setUnverifiedBrands] = useState([]);
  const [unverifiedCategories, setUnverifiedCategories] = useState([]);

  const defaultValues = {
    priceUnit: "TRY",
    productName: "",
    purchaseDate: "",
    productPrice: 0,
    unverifiedCategoryId: "",
    brand: "",
    customBrand: "",
    billFile: null,
  };

  const form = useForm({
    resolver: yupResolver(productSchema),
    defaultValues,
    mode: "onChange",
  });

  const { watch, trigger, setValue, handleSubmit, control, formState } = form;
  const watchCategory = watch("unverifiedCategoryId");
  const watchBrand = watch("brand");
  const watchCustomBrand = watch("customBrand");

  const handleStepValidation = async () => {
    if (step === FORM_STEPS.CATEGORY) {
      const isValid = await trigger("unverifiedCategoryId");
      if (isValid) {
        setStep(FORM_STEPS.BRAND);
      }
    } else if (step === FORM_STEPS.BRAND) {
      const isValid = await trigger(["brand", "customBrand"]);
      if (isValid) setStep(FORM_STEPS.DETAILS);
    }
  };

  const onSubmit = async (data) => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const formData = new FormData();
    formData.append("file", data.billFile);
    const queryParams = {
      name: data.productName,
      unverifiedProductBrandId: data.brand,
      brandName: data.customBrand,
      unverifiedCategoryId: data.unverifiedCategoryId,
      warrantyStartDate: moment(data.purchaseDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD",
      ),
      warrantYear: 2,
      priceUnit: data.priceUnit,
      productPrice: data.productPrice,
    };

    try {
      const response = await axios.post("/unverified-products", formData, {
        params: queryParams,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      onOpenChange();
      toast({
        description: t("productsPage.productSuccessfullyAdded"),
        variant: "success",
      });
      form.reset(defaultValues);
      setStep(FORM_STEPS.CATEGORY);
    } catch (error) {
      const errorText = parseError(error.response.data.errors);
      toast({
        description: errorText,
        variant: "error",
      });
    }
  };

  const renderSelectedInfo = () =>
    step === FORM_STEPS.CATEGORY ? null : (
      <div className="py-4">
        <div className="flex gap-4">
          {watchCategory && (
            <SelectableCard
              item={{
                name: unverifiedCategories.find(
                  (cat) => cat.id === watchCategory,
                )?.name,
                logoUrl: unverifiedCategories.find(
                  (cat) => cat.id === watchCategory,
                )?.logoUrl,
              }}
            />
          )}
          {(watchBrand || watchCustomBrand) && (
            <SelectableCard
              item={{
                name:
                  unverifiedBrands.find((br) => br.id === watchBrand)?.name ||
                  watchCustomBrand,
                logoUrl: unverifiedBrands.find((br) => br.id === watchBrand)
                  ?.logoUrl,
              }}
            />
          )}
        </div>
      </div>
    );

  const renderCategoryStep = () => (
    <div>
      <h2 className="my-4 font-semibold  text-[#111729]">Kategoriler</h2>
      <div className="mb-6 grid grid-cols-3 gap-4">
        {unverifiedCategories?.map((category) => (
          <SelectableCard
            item={category}
            key={category.id}
            selected={watchCategory === category.id}
            onClick={() => {
              setValue("unverifiedCategoryId", category.id);
            }}
          />
        ))}
      </div>
    </div>
  );
  const [showMore, setShowMore] = useState(false);
  const renderBrandStep = () => {
    return (
      <div className="flex flex-col gap-4">
        <h2 className="font-semibold">Markalar</h2>
        {unverifiedBrands.length > 0 && (
          <div className="mb-5 grid grid-cols-2 gap-4 sm:grid-cols-3">
            {unverifiedBrands
              .slice(0, showMore ? unverifiedBrands.length : 6)
              .map((brand) => (
                <SelectableCard
                  key={brand.id}
                  item={brand}
                  selected={watchBrand === brand.id}
                  onClick={() => {
                    setValue("brand", brand.id);
                    setValue("customBrand", "");
                    trigger("brand"); // Güncelleme sonrasında formun durumu yeniden değerlendiriliyor
                  }}
                />
              ))}
          </div>
        )}
        {unverifiedBrands.length > 6 && (
          <button
            className="text-blue-500"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Daha az göster" : "Daha fazla göster"}
          </button>
        )}
        <FormField
          control={control}
          name="customBrand"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Yeni Marka Adı</FormLabel>
              <FormControl>
                <Input onFocus={() => setValue("brand", "")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    );
  };

  const renderDetailsStep = () => (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Ürün Bilgileri</h2>
      <FormField
        control={control}
        name="productName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Ürünün adı veya modeli nedir?</FormLabel>
            <FormControl>
              {/* <Input {...field} /> */}
              <AutoCompleteInput
                value={field.value}
                setValue={field.onChange}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="purchaseDate"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Ürünü ne zaman satın aldınız?</FormLabel>
            <FormControl
              ref={withMask({
                mask: "99/99/9999",
              })}
            >
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="productPrice"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Ürünün fiyatı nedir?</FormLabel>
            <FormControl>
              <PriceInputPrice
                onChange={(value) => field.onChange(value)}
                valueUnit={watch("priceUnit")}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="billFile"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("productsPage.clickHereToUploadBill")}</FormLabel>
            <FormControl>
              <Input
                type="file"
                onChange={(e) =>
                  field.onChange(e.target.files ? e.target.files[0] : null)
                }
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );

  const renderDoneStep = () => (
    <div className="flex flex-col items-center justify-center gap-6 rounded-lg bg-white pt-10">
      <h2 className="font-medium text-[#101828]">Ürününüz Eklendi 🎉</h2>
      <p className="text-center text-sm text-[#677389]">
        Ürününüzle ilgili tüm bilgileri ve ek hizmetleri ürün sayfasından
        inceleyebilirsiniz.
      </p>
      <Button
        type="button"
        size="full"
        onClick={() => {
          setStep(FORM_STEPS.CATEGORY);
          form.reset(defaultValues);
          onOpenChange();
        }}
      >
        Ana Sayfaya Dön
      </Button>
    </div>
  );

  const stepContent = {
    [FORM_STEPS.CATEGORY]: renderCategoryStep,
    [FORM_STEPS.BRAND]: renderBrandStep,
    [FORM_STEPS.DETAILS]: renderDetailsStep,
    [FORM_STEPS.DONE]: () => renderDoneStep(),
  };

  const isStepDisabled = () => {
    if (step === FORM_STEPS.CATEGORY && !watch("unverifiedCategoryId"))
      return true;
    if (step === FORM_STEPS.BRAND && !watch("brand") && !watch("customBrand"))
      return true;
    if (step === FORM_STEPS.DETAILS && !formState.isValid) return true;
    return false;
  };

  useEffect(() => {
    const fetchUnverifiedBrands = async () => {
      try {
        const response = await axios.get("/unverified-product-brands", {
          params: {
            categoryId: watchCategory,
          },
        });
        setUnverifiedBrands(response.data);
      } catch (error) {
        console.error("Error fetching unverified brands:", error);
      }
    };

    const fetchUnverifiedCategories = async () => {
      try {
        const response = await axios.get("/unverified-product-categories");
        setUnverifiedCategories(response.data);
      } catch (error) {
        console.error("Error fetching unverified categories:", error);
      }
    };

    fetchUnverifiedBrands();
    fetchUnverifiedCategories();
  }, [watchCategory]);

  return (
    <Sheet
      open={open}
      onOpenChange={() => {
        form.reset(defaultValues);
        setStep(FORM_STEPS.CATEGORY);
        onOpenChange();
      }}
    >
      {formState.isSubmitting && <Loading title="Ürün ekleniyor..." />}
      <SheetContent
        side={isMobile ? "bottom" : "right"}
        className="overflow-auto rounded-lg bg-white p-4"
      >
        <SheetHeader>
          <SheetTitle>Ürün Ekle</SheetTitle>
        </SheetHeader>
        {renderSelectedInfo()}
        <Form {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto w-full max-w-md space-y-6"
          >
            {stepContent[step]()}
          </form>
        </Form>
        <SheetFooter className="mt-6">
          <div className="flex w-full justify-between gap-4">
            {step > FORM_STEPS.CATEGORY && step !== FORM_STEPS.DONE && (
              <Button
                size="full"
                onClick={() => setStep(step - 1)}
                variant="secondaryGray"
                disabled={formState.isSubmitting}
              >
                Geri
              </Button>
            )}
            {step !== FORM_STEPS.DONE && (
              <Button
                disabled={isStepDisabled()}
                className={cn({
                  "cursor-not-allowed opacity-50":
                    (step === FORM_STEPS.CATEGORY && !watchCategory) ||
                    (step === FORM_STEPS.BRAND &&
                      !watchBrand &&
                      !watchCustomBrand) ||
                    (step === FORM_STEPS.DETAILS && !formState.isValid) ||
                    formState.isSubmitting,
                })}
                size="full"
                type={step === FORM_STEPS.DETAILS ? "submit" : "button"}
                onClick={
                  step === FORM_STEPS.DETAILS
                    ? handleSubmit(onSubmit)
                    : handleStepValidation
                }
              >
                {step === FORM_STEPS.DETAILS ? "Kaydet" : "Devam"}
              </Button>
            )}
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default ProductForm;

import { Button } from "@egaranti/components";

import React, { useState } from "react";

import EmptyData from "../common/EmptyData";
import ProductCard from "./ProductCard";
import UnVerifiedProductDetailSheet from "./UnVerifiedProductDetailSheet";

import { motion } from "framer-motion";
import { t } from "i18next";

function UnVerifiedProductsList({
  unVerifiedProducts,
  fetchProducts,
  setOpenAddProductSheet,
}) {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [openDetailSheet, setOpenDetailSheet] = useState(false);

  const handleDetailModal = (product) => {
    setSelectedProduct(product);
    setOpenDetailSheet(true);
  };

  return (
    <div className="flex w-full flex-col">
      <h2 className="mb-4 text-sm font-medium text-gray-500 md:text-base md:text-gray-800">
        {t("productsPage.unapprovedProducts")}
      </h2>
      {unVerifiedProducts?.length > 0 ? (
        <motion.div className="grid grid-cols-1 items-start gap-5 md:grid-cols-3">
          {unVerifiedProducts?.map((product, index) => (
            <ProductCard
              key={index}
              onClick={() => handleDetailModal(product)}
              id={product.id}
              product={product}
              color="3"
              verified={false}
            />
          ))}
        </motion.div>
      ) : (
        <EmptyData
          className="mx-auto max-w-[400px]"
          title={t("productsPage.noData")}
          description={t("productsPage.noDataDesc2")}
        >
          <Button
            onClick={() => {
              setOpenAddProductSheet(true);
            }}
          >
            {t("productsPage.addUnapprovedProduct")}
          </Button>
        </EmptyData>
      )}
      <UnVerifiedProductDetailSheet
        open={openDetailSheet}
        onOpenChange={setOpenDetailSheet}
        selectedProduct={selectedProduct}
        fetchProducts={fetchProducts}
      />
    </div>
  );
}
export default UnVerifiedProductsList;

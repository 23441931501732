import {
  AlertDialog,
  AlertDialogContent,
  Button,
  Checkbox,
  Label,
} from "@egaranti/components";

import { useState } from "react";
import { useTranslation } from "react-i18next";

const UserAgreementModal = ({ onAccept, onReject }) => {
  const { i18n, t } = useTranslation();
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const handleAccept = () => {
    const acceptedAgreements = {
      membershipAgreement: isChecked1,
      commercialCommunicationAgreement: isChecked2,
    };
    onAccept(acceptedAgreements);
  };

  return (
    <AlertDialog open={open}>
      <AlertDialogContent className="flex flex-col gap-4 bg-white p-6">
        <div className="flex flex-col gap-4">
          <h2 className="text-2xl font-semibold">
            {t("userAgreementModal.title")}
          </h2>
        </div>
        <div className="mt-4 flex items-center">
          <Checkbox
            className="mr-2"
            checked={isChecked1}
            onCheckedChange={setIsChecked1}
          />
          <Label className="text-sm font-normal text-[#677389]">
            {i18n.language === "tr" ? (
              <span className="text-sm font-normal text-[#677389]">
                <a
                  className="text-[#0F6FFF] underline"
                  href="https://egaranti.com/tr/policy/usage-terms/"
                  target={"_blank"}
                >
                  Üyelik sözleşmesi
                </a>
                'ni okudum, kabul ediyorum.
              </span>
            ) : (
              <span className="text-sm font-normal text-[#677389]">
                I've read and accepted the{" "}
                <a
                  href="https://egaranti.com/tr/policy/usage-terms/"
                  className="text-[#0F6FFF] underline"
                  target={"_blank"}
                >
                  Membership Agreement.
                </a>
              </span>
            )}
          </Label>
        </div>
        <div className="mt-4 flex items-center">
          <Checkbox
            className="mr-2"
            checked={isChecked2}
            onCheckedChange={setIsChecked2}
          />
          <Label className="text-sm font-normal text-[#677389]">
            {i18n.language === "tr" ? (
              <span className="text-sm font-normal text-[#677389]">
                <a
                  className="text-[#0F6FFF] underline"
                  href="https://egaranti.com/tr/policy/privacy"
                  target={"_blank"}
                >
                  Kişisel Veri Aydınlatma Formu
                </a>
                'nu okudum.
              </span>
            ) : (
              <span className="text-sm font-normal text-[#677389]">
                I've read the{" "}
                <a
                  className="text-[#0F6FFF] underline"
                  href="https://egaranti.com/tr/policy/privacy"
                  target={"_blank"}
                >
                  Personal Data Disclosure Form
                </a>
              </span>
            )}
          </Label>
        </div>
        <div className="mt-5 flex justify-end gap-2">
          <Button variant="secondaryGray" onClick={onReject}>
            {t("userAgreementModal.reject")}
          </Button>
          <Button onClick={handleAccept} disabled={!isChecked1}>
            {t("userAgreementModal.accept")}
          </Button>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UserAgreementModal;

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  useToast,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import axios from "../../lib/api/axios";

import * as yup from "yup";

import { parseError } from "@/lib/utils";
import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

const EditSheet = ({ open, onOpenChange, getProfile, data }) => {
  const { t } = useTranslation();
  const { toast } = useToast();

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required("Lütfen adınızı giriniz.")
      .min(2, t("common.formErrors.nameMin"))
      .max(50, t("common.formErrors.nameMax")),
    lastName: yup
      .string()
      .required("Lütfen soyadınızı giriniz.")
      .min(2, t("common.formErrors.surnameMin"))
      .max(50, t("common.formErrors.surnameMax")),
    email: yup
      .string()
      .email(t("common.formErrors.email"))
      .required(t("common.formErrors.emailRequired")),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    form.reset({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    });
  }, [data]);

  const onSubmit = (data) => {
    axios
      .put("/individual-customers/info", data)
      .then((res) => {
        toast({
          description: t("accountPage.profileUpdateSuccess"),
          variant: "success",
        });
        onOpenChange();
        getProfile();
      })
      .catch((err) => {
        const errorText = parseError(err.response.data.errors);
        toast({
          description: errorText,
          variant: "error",
        });
      });
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side={isMobile ? "bottom" : "right"}
        className="rounded-lg bg-white p-4 md:m-4"
      >
        <SheetHeader>
          <SheetTitle>{t("accountPage.updateProfileInfo")}</SheetTitle>
        </SheetHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="mt-5 space-y-5"
          >
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("accountPage.name")}</Label>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("accountPage.surname")}</Label>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("accountPage.email")}</Label>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
        <SheetFooter className="mt-4">
          <Button onClick={form.handleSubmit(onSubmit)}>
            {t("common.save")}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default EditSheet;

const DialCodes = [
  {
    label: "TR",
    value: "TR",
    countryCode: "+90",
    icon: "🇹🇷",
    id: 1,
  },
  {
    label: "US",
    value: "US",
    countryCode: "+1",
    icon: "🇺🇸",
    id: 2,
  },
  {
    label: "UK",
    value: "UK",
    countryCode: "+44",
    icon: "🇬🇧",
    id: 3,
  },
  {
    label: "FR",
    value: "FR",
    countryCode: "+33",
    icon: "🇫🇷",
    id: 4,
  },
];

export default DialCodes;

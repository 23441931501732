import { Button, Input, Label, Tag, useToast } from "@egaranti/components";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import MessageBox from "@/components/Claims/MessageBox";
import ReplySheet from "@/components/Claims/Service/ReplySheet";
import DetailPageHeader from "@/components/common/DetailPageHeader";
import Loading from "@/components/common/Loading";
import Breadcrumb, {
  BreadcrumbItem,
} from "@/components/common/shared/breadcrumbs";

import axios from "@/lib/api/axios";
import { parseError } from "@/lib/utils";

const ServiceClaimDetailPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [claim, setClaim] = useState({});
  const [messages, setMessages] = useState([]);
  const { id } = useParams();

  const { toast } = useToast();
  const [openReplySheet, setOpenReplySheet] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [replyFile, setReplyFile] = useState(null);

  const handleShowReplyModal = () => {
    setOpenReplySheet(true);
  };

  const handleSendReply = (e) => {
    const formData = new FormData();
    formData.append("message", replyContent);
    if (replyFile) {
      formData.append("multipartFile", replyFile);
    }
    axios
      .post(
        `/individual-customer-product-service-claims/${claim.id}/message`,
        formData,
      )
      .then((res) => {
        setReplyContent("");
        setReplyFile(null);
        getClaimDetails();
        getMessages();
        setOpenReplySheet(false);
        toast({
          description: t("claimsPage.messageSentSuccess"),
          variant: "success",
        });
      })
      .catch((err) => {
        const errorText = parseError(err.response.data.errors);

        toast({
          description: errorText,
          variant: "error",
        });
      });
  };

  const getClaimDetails = () => {
    axios
      .get(`/individual-customer-product-service-claims/${id}`)
      .then((res) => {
        setClaim(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMessages = () => {
    axios
      .get(`/individual-customer-product-service-claims/${id}/message`)
      .then((res) => {
        setMessages(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getClaimDetails();
    getMessages();
  }, [id]);

  const STATUS_TYPES = {
    WAITING_MERCHANT: {
      label: t("claimsPage.waitingForAnswerFromCompany"),
      variant: "warning",
      value: "WAITING_MERCHANT",
    },
    WAITING_CUSTOMER: {
      label: t("claimsPage.waitingForAnswerFromCustomer"),
      variant: "warning",
      value: t("WAITING_CUSTOMER"),
    },
    APPROVED: {
      label: t("claimsPage.accepted"),
      variant: "success",
      value: "APPROVED",
    },
    REJECTED: {
      label: t("claimsPage.rejected"),
      variant: "danger",
      value: "REJECTED",
    },
  };

  const isFinished = claim.status === "APPROVED" || claim.status === "REJECTED";

  if (loading) return <Loading />;

  return (
    <div className="flex w-full flex-col">
      <DetailPageHeader
        title={t("claimsPage.serviceRequestDetails")}
        returnPath="/claims/service"
      />
      <Breadcrumb>
        <BreadcrumbItem link="/claims/service">
          {t("claimsPage.myClaims")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("claimsPage.claimDetails")}</BreadcrumbItem>
      </Breadcrumb>
      <div className="min-h-[calc(100vh_-_64px)] w-full bg-[#F9FAFC] p-4">
        <div className="mx-auto mt-6 flex max-w-[1000px] flex-col gap-5 rounded-lg border border-[#EAECF0] bg-white p-6">
          <h2 className="font-medium">{t("claimsPage.claimInfo")}</h2>
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="flex w-full flex-col gap-2">
              <Label>{t("claimsPage.productName")}</Label>
              <Input value={claim.individualCustomerProductName} disabled />
            </div>
          </div>
          <div className="flex w-full flex-col gap-2">
            <Label>{t("claimsPage.address")}</Label>
            <Input value={claim?.address?.title} disabled />
          </div>
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="flex w-full flex-col gap-2">
              <Label>{t("claimsPage.claimDate")}</Label>
              <Input value={claim.createdAt} disabled />
            </div>
            <div className="flex w-full flex-col gap-2">
              <Label>{t("claimsPage.lastUpdateDate")}</Label>
              <Input value={claim.updatedAt} disabled />
            </div>
          </div>
          <div className="flex w-full flex-col gap-2">
            <Label>{t("claimsPage.productProblem")}</Label>
            <Input value={claim.description} disabled />
          </div>
          <Tag variant="blue">{STATUS_TYPES[claim.status]?.label}</Tag>
        </div>
        <div className="mx-auto mt-6 flex max-w-[1000px] flex-col gap-5 rounded-lg border border-[#EAECF0] bg-white p-6">
          <h2 className="font-medium">{t("claimsPage.messages")}</h2>
          {messages?.map((message) => (
            <MessageBox message={message} />
          ))}
          {/* no message  */}
          {messages.length === 0 && (
            <div className="flex flex-col justify-between md:flex-row">
              <span className="text-[#677389]">
                {t("claimsPage.questions")}
              </span>
              {!isFinished && (
                <Button className="mt-4" onClick={handleShowReplyModal}>
                  {t("claimsPage.startMessaging")}
                </Button>
              )}
            </div>
          )}
          {!isFinished && messages.length > 0 && (
            <div>
              <Button className="mt-4" onClick={handleShowReplyModal}>
                {t("claimsPage.addReply")}
              </Button>
            </div>
          )}
        </div>
      </div>
      <ReplySheet
        open={openReplySheet}
        onOpenChange={setOpenReplySheet}
        setReplyFile={setReplyFile}
        replyContent={replyContent}
        setReplyContent={setReplyContent}
        handleSendReply={() => handleSendReply()}
      />
    </div>
  );
};

export default ServiceClaimDetailPage;

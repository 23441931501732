import { Toaster } from "@egaranti/components";

import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";

import mixpanel from "mixpanel-browser";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/tr";
import moment from "moment/moment";
import { useRegisterSW } from "virtual:pwa-register/react";

import { getUserCountry } from "@/lib/api/countryDetector";
import ScrollToTop from "@/lib/utils/components/ScrollToTop";
import AppRoutes from "@/routes";

export default function App() {
  mixpanel.init("3b972b51542eea57c5af9ed20ad22692", {
    track_pageview: true,
    persistence: "localStorage",
  });
  const intervalMS = 1800000;

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      r &&
        setInterval(async () => {
          if (r.installing || !navigator) return;

          if ("connection" in navigator && !navigator.onLine) return;

          const resp = await fetch(swUrl, {
            cache: "no-store",
            headers: {
              cache: "no-store",
              "cache-control": "no-cache",
            },
          });

          if (resp?.status === 200) await r.update();
        }, intervalMS);
    },
    onRegistered(r) {
      // eslint-disable-next-line prefer-template
      console.log("SW Registered: " + r);
    },
    onRegisterError(error) {
      console.log("SW registration error", error);
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  useEffect(() => {
    getUserCountry();
  }, []);

  return (
    <>
      <Toaster />
      <Router>
        <ScrollToTop />
        <AppRoutes />
      </Router>
      <div className="ReloadPrompt-container">
        {(offlineReady || needRefresh) && (
          <div className="ReloadPrompt-toast">
            <div className="ReloadPrompt-message">
              {offlineReady ? (
                <span>App ready to work offline</span>
              ) : (
                <span>
                  New content available, click on reload button to update.
                </span>
              )}
            </div>
            {needRefresh && (
              <button
                className="ReloadPrompt-toast-button"
                onClick={() => updateServiceWorker(true)}
              >
                Reload
              </button>
            )}
            <button
              className="ReloadPrompt-toast-button"
              onClick={() => close()}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </>
  );
}

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  useToast,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import Breadcrumb, { BreadcrumbItem } from "@/components/common/shared/breadcrumbs";

import { postAddress } from "@/lib/api/address";
import axios from "@/lib/api/axios";
import { parseError } from "@/lib/utils";
import { addressSchema } from "@/lib/utils/validation/schemas";

export default function NewAddress() {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const location = useLocation();
  const { toast } = useToast();

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  const form = useForm({
    resolver: yupResolver(addressSchema),
    defaultValues: {
      title: "",
      firstName: "",
      lastName: "",
      addressLine: "",
      cityId: "",
      districtId: "",
      postCode: "",
    },
  });

  const handleSave = (data) => {
    if (loading) return;

    setLoading(true);
    postAddress(data)
      .then((res) => {
        toast({
          description: t("Adres eklendi"),
          variant: "success",
        });
        navigateTo(
          location.state?.from ? location.state.from : "/account/address",
          {
            state: { product: location.state?.product },
          },
        );
      })
      .catch((err) => {
        const errorText = parseError(err.response.data.errors);
        toast({
          description: errorText,
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllCities = () => {
    axios.get("/addresses/cities").then((res) => {
      setCities(res.data);
    });
  };

  const handleSelectCity = (e) => {
    const cityId = e;

    axios.get(`/addresses/cities/${cityId}/districts`).then((res) => {
      setDistricts(res.data);
    });

    form.setValue("cityId", cityId);
    form.setValue("districtId", null);
  };

  useEffect(() => {
    getAllCities();
  }, []);

  return (
    <div className="flex w-full flex-col">
      <Breadcrumb>
        <BreadcrumbItem link="/account/address">
          {" "}
          {t("accountPage.myAddresses")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("accountPage.newAddress")}</BreadcrumbItem>
      </Breadcrumb>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSave)}
          className="w-full bg-[#F9FAFC] p-4"
        >
          <div className="mx-auto mt-6 flex max-w-[1000px] flex-col gap-5 rounded-lg border border-[#EAECF0] bg-white p-[30px]">
            <h2 className="mb-2 font-medium">{t("accountPage.addressInfo")}</h2>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("accountPage.title")}</Label>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col items-center gap-6 md:flex-row">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("accountPage.name")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("accountPage.surname")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="cityId"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("accountPage.province")}</Label>
                  <Select
                    onValueChange={(value) => {
                      handleSelectCity(value);
                      field.onChange(value);
                    }}
                    defaultValue={field.cityId}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {cities.map((item) => {
                        return (
                          <SelectItem key={item.id} value={String(item.id)}>
                            {item.name}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="districtId"
              render={({ field }) => (
                <FormItem>
                  <Label>{t("accountPage.district")}</Label>
                  <Select onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {districts.map((item) => {
                        return (
                          <SelectItem key={item.id} value={String(item.id)}>
                            {item.name}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="addressLine"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("accountPage.address")}</Label>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="w-1/2">
              <FormField
                control={form.control}
                name="postCode"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <Label>{t("accountPage.postCode")}</Label>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex justify-end gap-4">
              <Button
                onClick={() => {
                  navigateTo("/account/address");
                }}
                variant="secondaryGray"
              >
                {t("accountPage.goBack")}
              </Button>
              <Button onSubmit={form.handleSubmit(handleSave)}>
                {t("common.save")}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}

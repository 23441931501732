import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as AccountIcon } from "../../../assets/svgs/accounticon.svg";
import { ReactComponent as HomeIcon } from "../../../assets/svgs/homeicon.svg";
import { ReactComponent as LayoutGridIcon } from "../../../assets/svgs/layout-grid.svg";
import { ReactComponent as CheckVerifiedIcon } from "../../../assets/svgs/verifiedicon.svg";

export default function BottomNav() {
  return (
    <>
      <div className="fixed bottom-0 left-0 z-50 flex w-full items-center justify-between bg-white px-6 md:hidden">
        <NavbarButton
          navigateToPath="/"
          isActivePath="/"
          icon={HomeIcon}
          label="navbar.home"
        />
        <NavbarButton
          navigateToPath="/products"
          isActivePath="/products"
          icon={LayoutGridIcon}
          label="navbar.products"
        />
        <NavbarButton
          navigateToPath="/services"
          isActivePath="/services"
          icon={CheckVerifiedIcon}
          label="navbar.services"
        />
        <NavbarButton
          navigateToPath="/account"
          isActivePath="/account"
          icon={AccountIcon}
          label="navbar.account"
        />
      </div>
    </>
  );
}

const NavbarButton = ({ navigateToPath, isActivePath, icon: Icon, label }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();

  // if link is active, add active class to the link
  const handleActiveClass = (link) => {
    if (link === "/" && window.location.pathname === "/") {
      return "text-[#0049E6] stroke-[#0049E6]";
    } else if (link !== "/" && window.location.pathname.includes(link)) {
      return "text-[#0049E6] stroke-[#0049E6]";
    } else {
      return "";
    }
  };
  return (
    <button
      onClick={() => navigateTo(navigateToPath)}
      className={
        handleActiveClass(isActivePath) +
        ` flex w-full  flex-col items-center gap-[6px] pb-4 pt-3 text-xs font-medium`
      }
    >
      <Icon />
      <span>{t(label)}</span>
    </button>
  );
};

import React, { useEffect, useState } from "react";

import axios from "../../api/axios";

const useFetchVerifiedProduct = () => {
  const [verifiedProducts, setVerifiedProducts] = useState([]);
  const [unVerifiedProducts, setUnVerifiedProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    name: "",
    mainCategory: "",
    category: "",
    subCategory: "",
    subCategoryBrand: "",
    brand: "",
  });

  const clearFilters = () => {
    setFilters({
      name: "",
      mainCategory: "",
      category: "",
      subCategory: "",
      subCategoryBrand: "",
      brand: "",
    });
  };

  const applyFilters = () => {
    fetchProducts(filters);
  };

  const fetchProducts = (filtersToApply) => {
    setLoading(true);

    const params = {
      ...filtersToApply,
    };

    axios
      .get("/individual-customer-products/list", { params })
      .then((res) => {
        setVerifiedProducts(res.data);
      })
      .catch((err) => {
        console.error("Error fetching verified products", err);
      });

    // Fetch unverified products
    axios
      .get("/unverified-products", { params })
      .then((res) => {
        setUnVerifiedProducts(res.data.content);
      })
      .catch((err) => {
        console.error("Error fetching unverified products", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    verifiedProducts,
    unVerifiedProducts,
    loading,
    filters,
    setFilters,
    clearFilters,
    applyFilters,
    fetchProducts,
  };
};

export default useFetchVerifiedProduct;
